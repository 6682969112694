<template>
  <div class="TextEllipsis">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.TextEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px; // To prevent letters like `g` and `p` from being cut
}
</style>
